<template>
  <div class="addNewOrderss">
    <div class="left">
      <v-dialog v-model="errorMsgPopUp" max-width="300px" scrollable persistent>
        <v-card color="red accent-2">
          <v-card-title>Errors</v-card-title>
          <v-card-subtitle
            >There are {{ orderErrors.total }} errors , Kindly fill the
            following field properly</v-card-subtitle
          >
          <v-card-text class="py-2">
            <v-row
              v-for="(errorMsges, index) in orderErrors.allErrorMsg"
              :key="index"
            >
              <h4>In {{ errorMsges.shipment }}</h4>
              <ul>
                <li
                  v-for="(msg, index) in errorMsges.messages"
                  :key="index"
                  class="mb-n1"
                >
                  {{ msg }}
                </li>
              </ul>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-6 white--text"
              text
              color="black"
              @click="errorMsgPopUp = !errorMsgPopUp"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <small
        v-if="activeShipmentErrorData.preferedPayMode.error"
        style="color: red"
        class="mt-3 mb-n5 ml-3"
      >
        {{ orderErrors.total }} Errors
      </small> -->

      <v-card :loading="loading" :disabled="disabled">
        <div class="d-flex tabsArea">
          <v-tabs
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            v-model="tab"
          >
            <v-tab
              v-for="(item, index) in order"
              :key="item.constId"
              @click="
                swapData(item.constId);
                swapErrorData(item.constId);
              "
            >
              Shipment{{ index + 1 }}
              <v-icon dark class="mx-2" @click="deleteShipment(item)">
                mdi-close-circle
              </v-icon>
            </v-tab>

            <v-btn text dense class="mt-1 #F5F5F5" @click="createNewShipment">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-tabs>
        </div>

        <v-card flat>
          <addNewOrders
            :activeConstId="activeId"
            :activeShipmentData="activeShipmentData"
            :activeShipmentErrorData="activeShipmentErrorData"
            :validationTrigger="validationTrigger"
            :order="order"
            :orderErrors="orderErrors"
            :doValidate="doValidate"
          />
        </v-card>
      </v-card>
    </div>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="right">
      <checkout :order="order" :expansionPannelKey="expansionPannelKey" />
      <div class="placeOrder-btn">
        <router-link to="">
          <v-row justify="center" class="pa-4">
            <v-btn
              color="#8600D9"
              class="white--text"
              @click="termsAndConditionsDialogue = true"
              width="300"
              :disabled="disabled"
              height="50"
            >
              Place order
              <v-icon right dark> mdi-checkbox-marked-circle </v-icon>
            </v-btn>
          </v-row>
        </router-link>
        <v-container class="ml-2 mt-2">
          <v-row color="grey">
            Press + button to create multiple shipments
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- terms and conditions  -->
    <v-dialog v-model="termsAndConditionsDialogue" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Terms and Conditions
        </v-card-title>

        <v-card-text>
          <ol class="ol-list">
            <li>
              These conditions of carriage EXCLUDE LIABILITY on the part of
              Colombo Mail and its employees or agents for loss, damage and
              delay in certain circumstances, LIMIT LIABILITY to stated amounts
              where liability is accepted and REQUIRE NOTICE OF CLAIMS within
              strict time limits. Senders should note these conditions carefully
              and where necessary obtain insurance cover in order to protect
              their interests.
            </li>
            <li>
              To continue providing uninterrupted services to our valued
              customers with best possible service during this Covid-19 pandemic
              time, a temporary surcharge will be added on all international
              parcel, freight, and shipments until further notice.
            </li>
            <li>
              Rates and service quotations by employees and agents of Colombo
              Mail will be based upon information provided by the sender but
              final rates and service may vary based upon the shipment actually
              tendered and the application of these conditions. Colombo Mail is
              not liable for, nor will any adjustment, refund or credit of any
              kind be made, as a result of any discrepancy in any rate or
              service quotation made prior to the tender of the shipment and the
              rates, and other charges invoiced to the customer.
            </li>
            <li>
              Charges may be assessed based on dimensional weight. Dimensional
              weight is determined by multiplying a package’s length x height x
              width (all in centimetres) and dividing by 5000 or such other
              number as specified by Colombo Mail from time to time on fedex.com
              If the result exceeds the actual weight, additional charges may be
              assessed based on the dimensional weight.
            </li>
            <li>
              There is no limit on the aggregate weight of a multiple piece
              shipment provided each individual package within the shipment does
              not exceed the per package weight limit specified for the
              destination. For the bulk shipments require advance arrangement
              with Colombo Mail. Details are available upon request.
            </li>
            <li>
              The following items are not acceptable for carriage to any
              destination at any circumstance unless otherwise agreed to by
              Colombo Mail.
              <ul class="ul-list">
                <li>
                  Money (coins, cash, currency paper money and negotiable
                  instruments equivalent to cash such as endorsed stocks, bonds
                  and cash letters).
                </li>
                <li>
                  Explosives fireworks and other items of an incendiary or
                  flammable nature.
                </li>
                <li>
                  Human corpses, organs or body parts, human and animal embryos,
                  cremated or disinterred human remains.
                </li>
                <li>Firearms, weaponry, ammunition and their parts.</li>
                <li>
                  Foodstuffs, perishable food articles and beverages requiring
                  refrigeration or other environmental control.
                </li>
                <li>
                  Hazardous waste, including, but not limited to, used
                  hypodermic needles and/or syringes or medical waste.
                </li>
                <li>
                  Shipments requiring to obtain any special license or permit
                  for transportation, importation or exportation.
                </li>
                <li>
                  Shipments the carriage, importation or exportation of which is
                  prohibited by any law, statute or regulation.
                </li>
                <li>
                  Packages that are wet, leaking or emit an odor of any kind.
                </li>
                <li>Packages that are wrapped in kraft paper.</li>
              </ul>
            </li>
            <li>
              Each package within a shipment must be legibly and durably marked
              with the full name and complete postal address with the PIN code
              and telephone number of both the shipper and the recipient.
              Colombo Mail shall not be liable for non-delivery on account of
              incomplete or erroneous address being furnished by the shipper.
              Further, customer is fully liable to inform us if any erroneous in
              tracking number not later than twenty-four (24) hours since
              receive the tracking number.
            </li>
            <li>
              Colombo Mail reserves the right to add Rs.300 for register post
              charge and our service surcharges on shipments without notice when
              customer uses the Sri Lankan post. In addition, Sri Lankan post
              terms & condition shall be imposed.
            </li>
            <li>
              Rates will be updated in each month based on US dollar and Sri
              Lanka rupee conversion.
            </li>
            <li>
              Shipments through express courier service will be dispatched on
              same day and tracking number will be updated on same day.
              Similarly, shipments through Singapore post and Swiss post will be
              dispatched on Friday and tracking number will be updated on
              following Wednesday.
            </li>
            <li>
              Save as expressly set out herein, Colombo Mail makes no
              warranties, express or implied.
            </li>
            <li>
              Maximum liability for lost/damage/shortage is USD 100 only or
              Invoice value, which ever is lower
            </li>
            <li>
              The above mentioned price may vary day by day depending on the
              exchange rate
            </li>
            <li>
              Kindly note that if the shipments to USPS, Singapore Post (Smart & Simple) and Hermes did not have
              a progress in the tracking during the said time frame, the
              compensation will be provided only for the shipping charge.
            </li>
          </ol>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="termsAndConditionsDialogue = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="acceptTermsAndConditions()">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { orderService } from "../../../services";
import addNewOrders from "../../../components/addNew";
import checkout from "../../../components/addNew/checkout.vue";
import { auth } from "../../../firebase";
export default {
  name: "AddNewOrder",
  components: {
    addNewOrders,
    checkout,
  },
  created() {
    this.init();
  },
  data() {
    return {
      tab: null,
      constId: 0,
      activeId: 0,
      activeShipmentData: null,
      activeShipmentErrorData: null,
      fetched: false,
      deleteDialog: false,
      deleteItem: null,
      expansionPannelKey: 0,
      doValidate: 0,

      //card
      loading: false,
      disabled: false,

      //order
      order: [],
      orderErrors: {
        total: 0,
        allErrorMsg: [],
        errors: [],
      },

      //validation
      validationTrigger: false,

      //error Msg pop up
      errorMsgPopUp: false,

      //termsandconditions
      termsAndConditionsDialogue: false,
    };
  },
  methods: {
    init() {
      console.log("orderdfghjk");
      this.createNewShipment();
      this.activeShipmentData = this.order[0];
      this.activeShipmentErrorData = this.orderErrors.errors[0];
      // console.log("order", this.newOrder);
      // this.order = this.newOrder;
      this.fetched = true;
    },

    //to create new shipment
    createNewShipment() {
      this.constId = this.constId + 1;
      console.log("creating new shipment", this.constId);

      //create shipment
      const shipment = {
        preferedPayMode: null,
        constId: this.constId,
        receiverInfo: {
          services: null,
          country: null,
          courierService: null,
          customerId: null,
          name: null,
          lastName: null,
          mobile: null,
          email: null,
          address1: null,
          address2: null,
          address3: null,
          address4: null,
          zipcode: null,
          // city: null,
          // state: null,
          company: null,
          vatOrTax: null,
        },
        totalWeight: 0,
        itemInfo: [
          {
            itemDescription: null,
            purpose: null,
            value: null,
            weight: null,
          },
        ],
        packageInfo: {
          height: null,
          width: null,
          length: null,
          packingMethod: null,
        },
        payMode: {
          name: null,
          transit: null,
          rate: 0,
          description: null,
          img: null,
        },
      };
      console.log("shipment", shipment);
      this.order.push(shipment);

      //create shipment errors
      const shipmentError = {
        preferedPayMode: { error: false, msg: [] },
        constId: this.constId,
        total: 0,
        errorMsg: [],
        receiverInfo: {
          services: { error: false, msg: [] },
          country: { error: false, msg: [] },
          courierService: { error: false, msg: [] },
          customerId: { error: false, msg: [] },
          name: { error: false, msg: [] },
          lastName: { error: false, msg: [] },
          mobile: { error: false, msg: [] },
          email: { error: false, msg: [] },
          address1: { error: false, msg: [] },
          address2: { error: false, msg: [] },
          address3: { error: false, msg: [] },
          address4: { error: false, msg: [] },
          zipcode: { error: false, msg: [] },
          company: { error: false, msg: [] },
          vatOrTax: { error: false, msg: [] },
        },
        totalWeight: { error: false, msg: [] },
        itemInfo: [
          {
            itemDescription: { error: false, msg: [] },
            purpose: { error: false, msg: [] },
            value: { error: false, msg: [] },
            weight: { error: false, msg: [] },
          },
        ],
        packageInfo: {
          height: { error: false, msg: [] },
          width: { error: false, msg: [] },
          length: { error: false, msg: [] },
          packingMethod: { error: false, msg: [] },
        },
        payMode: {
          name: { error: false, msg: [] },
          transit: { error: false, msg: [] },
          rate: { error: false, msg: [] },
          description: { error: false, msg: [] },
          img: { error: false, msg: [] },
        },
      };
      console.log("shipmentError", shipmentError);
      this.orderErrors.errors.push(shipmentError);

      //swap active data info
      this.activeId = this.constId;
      this.activeShipmentData = shipment;
      this.activeShipmentErrorData = shipmentError;

      this.tab = this.order.length - 1;
    },

    //to swap data on tab change
    swapData(id) {
      this.activeId = id;
      this.order.forEach((item) => {
        if (item.constId == id) this.activeShipmentData = item;
      });
    },
    //to swap error data on tab change
    swapErrorData(id) {
      this.orderErrors.errors.forEach((item) => {
        if (item.constId == id) this.activeShipmentErrorData = item;
      });
    },

    //deleting a shipment
    deleteShipment(data) {
      // this.deleteShipment(data);
      console.log("data", data);
      this.deleteDialog = true;
      this.deleteItem = data;
      console.log("deleteItem", this.deleteItem);
    },
    deleteItemConfirm() {
      console.log("this.deleteItem", this.deleteItem);
      // this.removeShipment(this.deleteItem);

      if (this.order.length > 1) {
        for (let i = 0; i < this.order.length; i++) {
          let shipment = this.order[i];
          let nextShipment = null;

          // let shipmentError = this.orderErrors.errors[i];
          let nextShipmentError = null;

          console.log("shipment", shipment);
          if (i == this.order.length - 1) {
            nextShipment = this.order[i];
            nextShipmentError = this.orderErrors.errors[i];
          } else {
            nextShipment = this.order[i + 1];
            nextShipmentError = this.orderErrors.errors[i + 1];
          }

          console.log("nextShipment", nextShipment);
          if (shipment.constId == this.deleteItem.constId) {
            this.order.splice(i, 1);
            this.orderErrors.errors.splice(i, 1);

            this.activeShipmentData = nextShipment;
            this.activeShipmentErrorData = nextShipmentError;
            this.activeId = nextShipment.constId;

            this.startValidation();
            break;
          }
        }

        console.log("%cOrder after deletion", "color: yellow", this.order);
      } else {
        console.error("Shipment Cannot be deleted");
      }
      this.closeDelete();
    },
    closeDelete() {
      this.deleteDialog = false;
      this.deleteItem = null;
    },

    //terms and Conditions
    async acceptTermsAndConditions() {
      this.termsAndConditionsDialogue = false;
      await this.createOrder();
    },

    //to create a new order in db
    async createOrder() {
      await this.startValidation();
      if (this.orderErrors.total == 0) {
        if (confirm("Are you sure to place order?")) {
          this.loading = true;
          this.disabled = true;
          console.log("Errors zero");
          const newOrderPlaced = {};
          newOrderPlaced.uId = auth.currentUser.uid;
          newOrderPlaced.details = this.order;

          console.log("OrderPlaced", newOrderPlaced);
          console.log(JSON.stringify(newOrderPlaced));
          await orderService
            .createNewOrder(newOrderPlaced)
            .then((result) => {
              console.log("order added", result);
            })
            .catch((err) => {
              console.error(err);
            });

          this.$router.replace("/myOrders");
        }
      } else {
        this.errorMsgPopUp = true;
        console.log(`there are ${this.orderErrors.total} errors`);
      }
      this.loading = false;
      this.disabled = false;
    },

    startValidation() {
      console.log("Validation triggered");
      this.validationTrigger = !this.validationTrigger;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.v-tab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f28dfd;
  color: #8a0099;
  .active {
    color: #8a0099;
    background-color: #8a0099;
  }
}

.delete-btn {
  width: 10px !important;
  border-radius: 40px;
  padding: 0 !important;
}
.addNewOrderss {
  margin: 20px;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 60%;
}
.right {
  margin-right: 20px;
  width: 35%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .addNewOrderss {
    margin: 0px;
    width: 100vw;
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left {
    width: 98vw;
  }
  .right {
    margin-top: 30px;
    width: 98vw;
    height: auto;
  }
  .placeOrder-btn {
    margin-top: 30px;
  }
}
</style>