<template>
  <div class="checkout" :key="expansionPannelKey">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(shipment, index) in order"
        :key="shipment.constId"
      >
        <v-expansion-panel-header>
          <p>Shipment {{ index + 1 }}</p>
          <v-spacer></v-spacer>
          <v-chip class="green white--text"
            >LKR {{ shipment.payMode.rate }}</v-chip
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul id="shipmentSnips">
            <li
              v-if="
                shipment.receiverInfo.country != undefined &&
                shipment.receiverInfo.country != null
              "
            >
              <b>To: </b>
              {{ shipment.receiverInfo.country.name }}
            </li>

            <li><b>Weight:</b> {{ shipment.totalWeight }}</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card>
      <v-card-text
        ><b>Order Total: LKR {{ orderTotalC }}.00</b></v-card-text
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: "checkout",
  props: ["order", "expansionPannelKey"],
  computed: {
    orderTotalC() {
      let total = 0;
      this.order.forEach((shipment) => {
        total += parseInt(shipment.payMode.rate);
      });
      return total;
    },
  },

  data() {
    return {
      orderTotal: 0,
    };
  },
  watch: {
    expansionPannelKey(newVal, oldVal) {
      this.orderTotal = 0;
      console.log("expansionPannelKey", newVal, oldVal);
      this.order.forEach((shipment) => {
        this.orderTotal += shipment.payMode.rate;
      });
    },
  },
  methods: {
    //to calculate total cost of shipment
    // calTotal() {
    //   let sum = 0;
    //   this.order.forEach((shipment) => {
    //     sum = sum + shipment.payMode.rate;
    //   });
    //   this.orderTotal = sum;
    // },
  },
};
</script>

<style scoped lang="scss">
.v-expansion-panels {
  min-width: 300px;
}
.v-expansion-panel-header {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0px;
  }
}
#shipmentSnips {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  li {
    padding: 5px 20px;
    font-size: 14px;
    border-radius: 3px;
    background-color: rgb(153, 0, 158);
    color: rgb(255, 255, 255);
    margin-top: 7px;
    margin-right: 7px;
  }
}
</style>